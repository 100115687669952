.language-btn {
    width: 111px;
    height: 32px;
    background: #fff;
    border: 1px solid #dadada;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    .selected {
        color: #289cd8;
        font-weight: 700;
    }
}

table {
    .thead-sticky {
        position: sticky;
        top: 0;
    }
    thead {
        vertical-align: bottom;
        background: #f4faff;
        color: #222;
        border: 1px solid transparent;
        tr {
            th {
                padding: 16px !important;
            }
        }
    }
    tbody {
        border: 1px solid transparent;
        tr {
            td {
                padding: 16px !important;
                &:last-child.controls {
                    display: flex;
                    justify-content: center;
                    gap: 8px;
                }
                .controls {
                    display: flex;
                    justify-content: center;
                    gap: 8px;
                }
                .item {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    .staff-img-group {
                        width: 60px;
                        height: 60px;
                        img {
                            // position: absolute;
                            width: 60px;
                            height: 60px;
                            &.staff-img {
                                display: block;
                                &-hover {
                                    display: none;
                                }
                            }
                        }

                        &:hover {
                            .staff-img {
                                display: none;
                                &-hover {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
            &:nth-child(even) {
                background: #fafafa;
            }
        }
    }
}

p {
    margin-bottom: 0;
}

.image_preview {
    border-radius: 50%;
    object-fit: cover;
    width: 200px;
    height: 200px;
    align-self: center;
    border: 1px solid #212529;
}
.text-limit {
    display: -webkit-box;
    -webkit-line-clamp: 5; /* số dòng hiển thị */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
